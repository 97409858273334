var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"zvwTEsX5bdxaKAValoLdJ"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const environment = process.env.NEXT_PUBLIC_APP_ENV;

// Note: if you want to override the automatic release value, do not set a
// `release` value here - use the environment variable `SENTRY_RELEASE`, so
// that it will also get attached to your source maps
Sentry.init({
  dsn: 'https://1641f497268d4880bf0df67055daddc5@o148425.ingest.sentry.io/5252126',
  environment,
  attachStacktrace: true,
  // 本番と Staging で同じ設定にしたい。そのため双方の domain に含まれる共有の文字列が含まれていればエラーを飛すようにする。
  // `manga-no` であれば一般的でないのでノイズが混ざることも少ないだろう。
  // see: https://docs.sentry.io/platforms/javascript/configuration/options/#allow-urls
  allowUrls: "manga-no",
  // 主に Sentry にイベントを送らない設定を記述している
  beforeSend: (event) => {
    // Yahoo JAPAN! の検索クローラのエラーがうるさいので止める
    // see: https://support.yahoo-net.jp/PccSearch/s/article/H000007955
    for (const yUA of [
      "Y!J-BRU/VSIDX",
      "Y!J-BRW",
      "Y!J-ASR",
      "Y!J-MMP/dscv",
      "Y!J-WSC",
    ]) {
      // eslint-disable-next-line no-undef
      if (navigator.userAgent.includes(yUA)) return null;
    }

    return event;
  },
});
Sentry.configureScope((scope) => {
  scope.setTag("client", true);
});
