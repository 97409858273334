import { FieldMergeFunction } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { StrictTypedTypePolicies } from "generated/graphql";

// 同階層に同じ type で片方にページネーションがあり片方に無い場合に落ちるのを防ぐワークアラウンド
// Apollo Clientをアップデートしたら relayStylePagination() に戻せる
// see: https://box.hatena.sh/hatenamanga/2021%2F01%2F05_%E3%83%9E%E3%83%B3%E3%82%AC%E3%83%8E_edges_%E5%86%85%E3%81%AE_node_%E3%82%92_alias_%E3%81%A7%E8%A4%87%E6%95%B0%E5%AE%9A%E7%BE%A9%E3%81%97%E3%81%A6%E3%81%8A%E3%82%8A%E3%80%81Apollo_%E3%81%AE%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E8%A8%AD%E5%AE%9A%E3%81%A7_relayStylePagination_%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E7%89%87%E6%96%B9%E3%81%AE_node_%E3%81%AE%E3%81%BF%E3%81%AB%E5%AE%9A%E7%BE%A9%E3%81%97%E3%81%9F%E6%99%82%E3%80%81%E9%80%9A%E4%BF%A1%E5%BE%8C%E3%81%AB_%60TypeError:_Cannot_read_property_'edges'_of_undefined%60_%E3%81%A7%E8%90%BD%E3%81%A1%E3%82%8B
function wrappedRelayStylePagination(
  keyArgs?: Parameters<typeof relayStylePagination>[0]
) {
  const policy = relayStylePagination(keyArgs);
  const merge = policy.merge;
  if (typeof merge === "function") {
    const wrappedMerge: FieldMergeFunction = (existing, incoming, options) => {
      // from: https://github.com/apollographql/apollo-client/blob/d3c7218a0bcd1722f4e0c4636632744564456fb6/src/utilities/policies/pagination.ts#L137-L143
      if (!existing) {
        existing = {
          edges: [],
          pageInfo: {
            hasPreviousPage: false,
            hasNextPage: true,
            startCursor: "",
            endCursor: "",
          },
        };
      }
      if (!incoming) {
        return existing;
      }
      return merge(existing, incoming, options);
    };
    policy.merge = wrappedMerge;
  }

  return policy;
}

/**
 * Apollo Client の cache ID の設定。
 *
 * 何も指定が無い Type は typescript-apollo-client-helpers によって TypePolicy の型を生成し、
 * 型チェックを行っているために書いておく必要がある。
 * Connection で replayStylePagination の指定を忘れるケースが出てきたため、
 * 新規に Type を追加した際考慮されるようにしている。
 * Type だけ追加したものは keyFields などに何も記載しなければデフォルトの cache ID の設定になる。
 *
 * see:
 *   - https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
 *   - https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
 */
export const apolloTypePolicies: StrictTypedTypePolicies = {
  Query: {
    keyFields: [],
    fields: {
      sites: relayStylePagination(),
      search: relayStylePagination(["keyword"]),
      portfolios: relayStylePagination(),
      newWorks: relayStylePagination(),
      newWorks2: relayStylePagination(),
      hotWorks: { merge: false },
      hotNewEpisodes: { merge: false },
      sellingNewEpisodes: { merge: false },
      infoEntries: { merge: false },
    },
  },
  GuestViewer: {
    keyFields: [],
  },
  RegisteredViewer: {
    keyFields: [],
    fields: {
      daredemoComments: relayStylePagination(),
      illustrations: relayStylePagination(),
      notifications: relayStylePagination(),
      purchasedEpisodesGroupedByWork: relayStylePagination(),
      purchaseHistory: relayStylePagination(),
      watchedUsers: relayStylePagination(),
      watchingUsers: relayStylePagination(),
      withdrawalRequests: relayStylePagination(),
      works: relayStylePagination(),
      yawarakaComments: relayStylePagination(),
    },
  },
  Site: {
    fields: {
      illustrations: relayStylePagination(),
      timeline: relayStylePagination(["query"]),
      links: {
        merge: false,
      },
    },
  },
  Tag: {
    fields: {
      works: relayStylePagination(),
    },
  },
  Work: {
    fields: {
      episodes: wrappedRelayStylePagination(["direction"]),
      viewerEpisodes: relayStylePagination(),
      illustrations: relayStylePagination(),
      tags: { merge: false },
    },
  },
  Episode: {
    fields: {
      pages: wrappedRelayStylePagination(),
      daredemoComments: relayStylePagination(),
      yawarakaComments: relayStylePagination(),
      yawarakaCommentPostedUsers: relayStylePagination(),
      timelineImages: {
        merge: false,
      },
    },
  },
  // see: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
  WorkStatistic: {
    merge: true,
  },
  EpisodeStatistic: {
    merge: true,
  },
  SalesInfo: {
    merge: true,
  },
  EpisodeUserAdCollection: {
    merge: true,
  },
  Ranking: {
    keyFields: [],
    fields: {
      daily2: relayStylePagination(),
      weekly2: relayStylePagination(),
      monthly2: relayStylePagination(),
      total2: relayStylePagination(),
    },
  },
  AdSlotCollection: {},
  AddPortfolioCategoryResult: {},
  AddPortfolioWorkExternalResult: {},
  AddPortfolioWorkManganosResult: {},
  AnswerSurveyPayload: {},
  AnswerSurveyFromAuthorPayload: {},
  AuthenticateOIDCAuthenticationRequestResponse: {},
  BankAccount: {},
  BankAccountInfo: {},
  Banner: {},
  Bookmarkable: {},
  CancelWithdrawalPayload: {},
  Comment: {},
  CommentDaredemo: {},
  CommentDaredemoConnection: {},
  CommentDaredemoEdge: {},
  CommentYawaraka: {},
  CommentYawarakaConnection: {},
  CommentYawarakaEdge: {},
  ContestApplication: {},
  CreateImage2Result: {},
  DeleteCommentPayload: {},
  DeleteEpisodePayload: {},
  DeleteIllustrationPayload: {},
  DeletePortfolioCategoryResult: {},
  DeletePortfolioWorkExternalResult: {},
  DeleteUserPayload: {},
  DeleteWorkPayload: {},
  DisconnectOIDCClientPayload: {},
  EpisodeAdTagCollection: {},
  EpisodeConnection: {},
  EpisodeEdge: {},
  EpisodePage: {},
  EpisodePageConnection: {},
  EpisodePageEdge: {},
  FindOrCreateTagResult: {},
  IMobileAd: {},
  Illustration: {},
  IllustrationConnection: {},
  IllustrationEdge: {},
  IllustrationImage: {},
  Image: {},
  ImageDimension: {},
  InfoEntry: {},
  LogPageLeavePositionPayload: {},
  MonthlyEpisodeSalesSummary: {},
  MonthlySalesSummary: {},
  MonthlyWorkSalesSummary: {},
  NewWorksConnection: {},
  Node: {},
  Notification: {},
  NotificationConnection: {},
  NotificationEdge: {},
  NotificationEntity: {},
  NotificationSettings: {},
  OIDCAuthenticationRequest: {},
  OIDCAuthenticationRequestValidateResponse: {},
  OIDCAuthenticationResponse: {},
  OIDCClient: {},
  PageInfo: {},
  PickupTag: {},
  Portfolio: {},
  PortfolioCategory: {},
  PortfolioConnection: {},
  PortfolioEdge: {},
  PortfolioWorkExternal: {},
  PortfolioWorkExternalURL: {},
  PortfolioWorkMangano: {},
  Purchasable: {},
  Purchase: {},
  PurchaseConnection: {},
  PurchaseEdge: {},
  RankingConnection2: {},
  RankingEdge2: {},
  ReadNotificationPayload: {},
  ReadingHistory: {},
  RecommendedTag: {},
  RequestWithdrawalPayload: {},
  SalesHistoryItem: {},
  SearchResultConnection: {},
  SearchResultEdge: {},
  SettleTransactionsPayload: {},
  SiteConnection: {},
  SiteEdge: {},
  SiteLink: {},
  Theme: {},
  TimelineItem: {},
  TimelineItemConnection: {},
  TimelineItemEdge: {},
  UpdateNotificationPayload: {},
  UpdatePortfolioBannerImageResult: {},
  UpdatePortfolioCategoryPositionsResult: {},
  UpdatePortfolioCategoryResult: {},
  UpdatePortfolioWorkExternalResult: {},
  UpdatePortfolioWorkManganoResult: {},
  UpdatePurchaseStatusPayload: {},
  UpdateSalesSummaryPayload: {},
  User: {},
  UserConnection: {},
  UserEdge: {},
  ViewCount: {},
  Viewer: {},
  WithdrawalRequest: {},
  WithdrawalRequestConnection: {},
  WithdrawalRequestEdge: {},
  WithdrawalRequestRefund: {},
  WorkConnection: {},
  WorkEdge: {},
  WorkTag: {},
};
